// require('./bootstrap');
window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
import Alpine from 'alpinejs';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();
import Splide from 'alpine-splide';
Alpine.data('Splide', Splide);
window.Alpine = Alpine;
Alpine.start();
